<template>
  <div class="game-frame-container">
    <iframe id="gameFrame" class="gameFrame" :src="this.gameUrl" />
  </div>
</template>
<script>
import { DevicePlatform } from "@/constants/enums";
import { GAME_LAUNCH_GAME, GAME_RESET_LAUNCH_GAME_RESPONSE, GAME_TYPE_GAMES } from "@/store/game.module";
import { CACHE_KEY } from "@/constants/constants";
import { uiHelper, locale, errorCodeHelper } from "@/util";

export default {
  name: "GameLobby",
  data: () => ({
    providerCode: "",
    type: "",
    gameUrl: "",
    storageRngGames: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_LIST}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}_` + "rng"),
    storageLdGames: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_LIST}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}_` + "ld"),
    storageSbGames: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_LIST}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}_` + "sb"),
    storageFishingGames: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_LIST}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}_` + "fishing"),
    storageSpecialGames: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_LIST}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}_` + "special"),
    storageTableGames: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_LIST}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}_` + "table"),
    storageCricketGames: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_LIST}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}_` + "cricket"),
    storageLotteryGames: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_LIST}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}_` + "lottery"),
    storageCrashGames: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_LIST}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}_` + "crash")
  }),
  computed: {
    launchGameResponseComplete() {
      return this.$store.state.game.launchGameResponse.complete;
    },
    CasinoGames() {
      return this.$store.state.game.CasinoGames;
    },
    SlotGames() {
      return this.$store.state.game.SlotGames;
    },
    FishingGames() {
      return this.$store.state.game.FishingGames;
    },
    SportsbookGames() {
      return this.$store.state.game.SportsbookGames;
    },
    SpecialGames() {
      return this.$store.state.game.SpecialGames;
    },
    CricketGames() {
      return this.$store.state.game.CricketGames;
    },
    LotteryGames() {
      return this.$store.state.game.LotteryGames;
    },
    CrashGames() {
      return this.$store.state.game.CrashGames;
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn;
    }
  },
  watch: {
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn;
    },
    launchGameResponseComplete() {
      let response = this.$store.state.game.launchGameResponse;
      if (response.complete) {
        if (response.success) {
          this.launchGameSuccess = response.success;
          let responseGameUrl = response.gameUrl;

          if (this.providerCode === "png") {
            this.launchGameSuccess = response.success;
            this.gameUrl = response.gameUrl;
            this.initializePNGGameCommunicator();
          } else if (this.providerCode === "betswiz" || this.providerCode === "ludo") {
            this.launchGameSuccess = response.success;
            console.log(response.gameUrl);
            window.location = response.gameUrl;
          } else {
            if (responseGameUrl.indexOf("https://") == 0) {
              if (uiHelper.getPlatform() == DevicePlatform.DESKTOP) {
                this.launchGameSuccess = response.success;
                this.gameUrl = response.gameUrl;
              } else {
                window.location = response.gameUrl;
              }
            } else {
              window.location = responseGameUrl;
            }
          }

          if (this.providerCode.toLowerCase() === "tbt") {
            this.listenTBTCallBackEvent();
          }
        } else {
          let dialog = this.$parent.initializePageDialogProperty();
          dialog.title = locale.getMessage(`label.${response.action}`);
          dialog.dialogXButton = null;
          if (response.code != 0 && !response.success) {
            dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code));

            dialog.button.push({
              title: locale.getMessage(`label.close`),
              action: this.closeGamePage,
              class: "primary white--text"
            });
          }
          this.$parent.openPageDialog(dialog);
        }
      }
      this.$store.dispatch(`${GAME_RESET_LAUNCH_GAME_RESPONSE}`);
    },
    CrashGames() {
      this.storageCrashGames = this.$store.state.game.CrashGames;
    },
    CasinoGames() {
      this.storageLdGames = this.$store.state.game.CasinoGames;
    },
    SlotGames() {
      this.storageRngGames = this.$store.state.game.SlotGames;
    },
    FishingGames() {
      this.storageFishingGames = this.$store.state.game.FishingGames;
    },
    SportsbookGames() {
      this.storageSbGames = this.$store.state.game.SportsbookGames;
    },
    SpecialGames() {
      this.storageSpecialGames = this.$store.state.game.SpecialGames;
    },
    TableGames() {
      this.storageTableGames = this.$store.state.game.TableGames;
    },
    CricketGames() {
      this.storageCricketGames = this.$store.state.game.CricketGames;
    },
    LotteryGames() {
      this.storageLotteryGames = this.$store.state.game.LotteryGames;
    }
  },
  created() {
    this.initializeGamePage();
  },
  methods: {
    closeGamePage() {
      this.$parent.closePageDialog;
      window.close();
    },
    async initializeGamePage() {
      this.type = this.$route.params.type;
      if (this.type == "ld" && (this.storageLdGames == null || this.storageLdGames.find(x => x.provider == this.$route.params.provider) == null)) {
        await this.getProviderGames();
      } else if (this.type == "fishing" && (this.storageFishingGames == null || this.storageFishingGames.find(x => x.provider == this.$route.params.provider) == null)) {
        await this.getProviderGames();
      } else if (this.type == "lottery" && (this.storageLotteryGames == null || this.storageLotteryGames.find(x => x.provider == this.$route.params.provider) == null)) {
        await this.getProviderGames();
      } else if (this.type == "rng" && (this.storageRngGames == null || this.storageRngGames.find(x => x.provider == this.$route.params.provider) == null)) {
        await this.getProviderGames();
      } else if (this.type == "sb" && (this.storageSbGames == null || this.storageSbGames.find(x => x.provider == this.$route.params.provider) == null)) {
        await this.getProviderGames();
      } else if (this.type == "special" && (this.storageSpecialGames == null || this.storageSpecialGames.find(x => x.provider == this.$route.params.provider) == null)) {
        await this.getProviderGames();
      } else if (this.type == "table" && (this.storageTableGames == null || this.storageTableGames.find(x => x.provider == this.$route.params.provider) == null)) {
        await this.getProviderGames();
      } else if (this.type == "cricket" && (this.storageCricketGames == null || this.storageCricketGames.find(x => x.provider == this.$route.params.provider) == null)) {
        await this.getProviderGames();
      } else if (this.type == "crash" && (this.storageCrashGames == null || this.storageCrashGames.find(x => x.provider == this.$route.params.provider) == null)) {
        await this.getProviderGames();
      }
      this.launchGame();
    },
    async getProviderGames() {
      let Obj = {
        currency: uiHelper.getCurrency(),
        type: this.type,
        size: "",
        page: "",
        platform: uiHelper.getPlatform(),
        category: "",
        provider: "",
        mode: "All"
      };
      await this.$store.dispatch(`${GAME_TYPE_GAMES}`, { Obj });
    },
    async launchGame() {
      let game = null;
      let isDemo = !this.$store.state.member.isLoggedIn;
      if (this.type == "ld") {
        game = this.storageLdGames.find(x => x.provider == this.$route.params.provider && x.id == this.$route.query.id && x.code == this.$route.query.code);
        if (game == null) {
          game = this.storageLdGames.find(x => x.provider == this.$route.params.provider);
        }
      } else if (this.type == "fishing") {
        game = this.storageFishingGames.find(
          x =>
            x.provider == this.$route.params.provider &&
            (this.$route.query.id == undefined || x.id == this.$route.query.id) &&
            (this.$route.query.code == undefined || x.code == this.$route.query.code)
        );
      } else if (this.type == "lottery") {
        game = this.storageLotteryGames.find(x => x.provider == this.$route.params.provider);
      } else if (this.type == "rng") {
        game = this.storageRngGames.find(x => x.provider == this.$route.params.provider && x.id == this.$route.query.id && x.code == this.$route.query.code);
      } else if (this.type == "sb") {
        game = this.storageSbGames.find(x => x.provider == this.$route.params.provider);
      } else if (this.type == "special") {
        game = this.storageSpecialGames.find(x => x.provider == this.$route.params.provider);
      } else if (this.type == "table") {
        game = this.storageTableGames.find(x => x.provider == this.$route.params.provider && x.id == this.$route.query.id && x.code == this.$route.query.code);
      } else if (this.type == "cricket") {
        game = this.storageCricketGames.find(x => x.provider == this.$route.params.provider);
      } else if (this.type == "crash") {
        game = this.storageCrashGames.find(x => x.provider == this.$route.params.provider && x.id == this.$route.query.id && x.code == this.$route.query.code);
      }

      this.providerCode = game.provider;
      let gameObj = {
        provider: game.provider,
        gameId: game.id,
        gameCode: game.code,
        isDemo: false,
        platform: uiHelper.getPlatform(),
        language: uiHelper.getLanguage(),
        webSite: uiHelper.getHostname(),
        currency: uiHelper.getCurrency(),
        gameType: game.type
      };
      console.log(gameObj);
      await this.$store.dispatch(`${GAME_LAUNCH_GAME}`, { gameObj });
    },
    initializePNGGameCommunicator() {
      let context = this;
      let gameFrame = document.getElementById("gameFrame");

      gameFrame.onload = function() {
        let listener = processGameMessage.bind(context);

        function processGameMessage(e) {
          switch (e.data.type) {
            case "logout":
              context.closeGamePage();
              break;

            case "reloadGame":
              window.removeEventListener("message", listener);

              context.initializeGamePage();
              break;
          }
        }

        window.addEventListener("message", listener);

        let parser = document.createElement("a");
        parser.href = gameFrame.src;
        let targetOrigin = parser.protocol + "//" + parser.host;

        gameFrame.contentWindow.postMessage(
          {
            messageType: "addEventListener",
            eventType: "reloadGame"
          },
          targetOrigin
        );

        gameFrame.contentWindow.postMessage(
          {
            messageType: "addEventListener",
            eventType: "logout"
          },
          targetOrigin
        );
      };
    },
    listenTBTCallBackEvent() {
      let context = this;

      window.onmessage = function(e) {
        if (e.data === "exitGame") {
          context.closeGamePage();
        }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
html {
  overflow: auto !important;
  width: 100%;
  height: 100%;
}

.game-frame-container {
  //position: relative;
  //padding-bottom: 56.25%;
  height: 100vh;
  overflow: hidden;
  background-color: black;
}

.gameFrame {
  width: 100%;
  height: 100%;
  border: 0;
  overflow: hidden;
}
</style>
